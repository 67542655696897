import './App.css';
import MainPage from './components/MainPage';
import CookieConsent from './CookieConsent';


function App() {
  return (
    <>
   <CookieConsent/>
   <MainPage/>
   </>
  );
}

export default App;
